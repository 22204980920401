























































import { defineComponent } from '@vue/composition-api';

import BaseButton from '../atoms/BaseButton.vue';
import LogoButton from '../atoms/LogoButton.vue';
import OnGooglePlay from '../atoms/OnGooglePlay.vue';
import { useAboutThisApp } from './AboutThisAppComposable';

export default defineComponent({
  name: 'BaseAboutThisApp',
  components: { BaseButton, LogoButton, OnGooglePlay },
  setup() {
    return useAboutThisApp();
  },
});
