







import { defineComponent } from '@vue/composition-api';

import { useMessages } from '@/base/app';

import MyApp from '../components/MyApp.vue';
import AboutThisApp from '../components/organisms/AboutThisApp.vue';

export default defineComponent({
  name: 'BaseAboutThisPage',
  components: { MyApp, AboutThisApp },
  setup() {
    const msgs = useMessages({ prefix: 'base.pages.aboutThisPage' });
    return { title: msgs.of('aboutThisApp') };
  },
});
