












import { computed, defineComponent, ref } from '@vue/composition-api';

import { useMessages } from '@/base/app';
import { isFailed, useGetTenantOwnTermsOfService } from '@/base/usecases';

import MyApp from '../components/MyApp.vue';
import TermsOfService from '../components/organisms/TermsOfService.vue';
import { useGlobalStore } from '../store';

export default defineComponent({
  name: 'BaseTenantOwnTermsOfServicePage',
  components: { MyApp, TermsOfService },
  setup() {
    const { onReady } = useGlobalStore();
    const body = ref<string>();
    const notFound = computed(() => !body.value);
    const loading = ref(false);

    const getTenantOwnTermsOfService = useGetTenantOwnTermsOfService();
    async function fetch() {
      loading.value = true;
      const res = await getTenantOwnTermsOfService.execute({});
      loading.value = false;
      if (isFailed(res)) return;
      body.value = res.tenantOwnTermsOfService?.body;
    }
    onReady(fetch);

    const msgs = useMessages({ prefix: 'base.pages.tenantOwnTermsOfServicePage' });
    return { body, loading, notFound, title: msgs.of('tenantOwnTermsOfService') };
  },
});
