







import { computed, defineComponent, ref } from '@vue/composition-api';

import { useMessages } from '@/base/app';
import { TermsOfService } from '@/base/domains';
import { isSucceeded, useGetLatestTermsOfService } from '@/base/usecases';

import MyApp from '../components/MyApp.vue';
import TermsOfServiceVue from '../components/organisms/TermsOfService.vue';
import { useGlobalStore } from '../store';

export default defineComponent({
  name: 'BaseTermsOfServicePage',
  components: { MyApp, TermsOfService: TermsOfServiceVue },
  setup() {
    const { onReady } = useGlobalStore();
    const termsOfService = ref<TermsOfService>();
    const body = computed(() => termsOfService.value?.body);
    const notFound = computed(() => !body.value);
    const loading = ref(false);

    const getLatestTermsOfService = useGetLatestTermsOfService();
    async function fetch() {
      loading.value = true;
      const res = await getLatestTermsOfService.execute({});
      if (isSucceeded(res)) termsOfService.value = res.termsOfService;
      loading.value = false;
    }
    onReady(fetch);

    const msgs = useMessages({ prefix: 'base.pages.termsOfServicePage' });
    return { body, loading, notFound, title: msgs.of('termsOfService') };
  },
});
