












import { computed, defineComponent, onMounted, ref, watch } from '@vue/composition-api';
import { useTimeoutFn } from '@vueuse/core';
import { RawLocation } from 'vue-router';

import { useRoute, useRouter } from '@/utils/VueUtils';

import MyApp from '../components/MyApp.vue';

export default defineComponent({
  name: 'BaseRedirectPage',
  components: { MyApp },
  setup() {
    const myApp = ref<InstanceType<typeof MyApp>>();
    const route = useRoute();
    const router = useRouter();

    const resetFrame = computed(() => !!route.query.resetFrame);

    const waiting = ref(false);

    function next() {
      if (waiting.value || myApp.value?.visible !== true) return;
      const { t, dq } = route.query;
      let to: RawLocation;
      if (t && typeof t === 'string') {
        try {
          to = JSON.parse(t);
        } catch (e) {
          to = { name: 'home' };
        }
      } else if (dq) {
        to = { name: 'home', query: { d: dq } };
      } else {
        to = { name: 'home' };
      }
      if (to) router.replace(to);
    }

    onMounted(() => {
      const { d } = route.query;
      let delay = 1000;
      if (d && typeof d === 'string') {
        const i = parseInt(d, 10);
        if (Number.isInteger(i)) delay = i;
      }
      waiting.value = true;
      useTimeoutFn(() => {
        waiting.value = false;
        next();
      }, delay);
    });

    watch(() => myApp.value?.visible, next);

    return { myApp, resetFrame };
  },
});
