







import { defineComponent } from '@vue/composition-api';

import { useMessages } from '@/base/app';

import MyApp from '../components/MyApp.vue';
import NotFound from '../components/organisms/NotFound.vue';

export default defineComponent({
  name: 'BaseNotFoundPage',
  components: { MyApp, NotFound },
  setup() {
    const msgs = useMessages({ prefix: 'base.pages.notFoundPage' });
    return { header: msgs.of('noPage'), body: msgs.of('description') };
  },
});
