import { computed, onMounted, ref } from '@vue/composition-api';

import { useMessages } from '@/base/app';
import { isFailed, useGetTenantOwnTermsOfService } from '@/base/usecases';
import { config } from '@/config';
import { googlePlayUrl } from '@/config/env';
import { readonly, useRoute } from '@/utils/VueUtils';

import { useGlobalStore } from '../../store';
import { toQuery } from '../atoms/ReturnButtonComposable';

const LINKS = [
  { value: 'help', href: config().app.helpUrl },
  { value: 'privacyPolicy', href: config().app.privacyPolicyUrl },
];

export function useAboutThisApp() {
  const { version, buildNumber, user, checkLimitation } = useGlobalStore();
  const msgs = useMessages({ prefix: 'base.organisms.aboutThisApp' });
  const route = useRoute();

  const existsTenantOwnTermsOfService = ref(false);
  const getTenantOwnTermsOfService = useGetTenantOwnTermsOfService();
  async function fetch() {
    if (checkLimitation('tenantOwnTermsOfService', false) !== 'enabled') return;
    const res = await getTenantOwnTermsOfService.execute({});
    if (isFailed(res)) {
      existsTenantOwnTermsOfService.value = false;
      return;
    }
    existsTenantOwnTermsOfService.value = !!res.tenantOwnTermsOfService?.body;
  }
  onMounted(fetch);

  const toTermsOfService = computed(() => ({
    name: 'termsOfService',
    query: { ...toQuery(route) },
  }));

  const toTenantOwnTermsOfService = computed(() => ({
    name: 'tenantOwnTermsOfService',
    query: { ...toQuery(route) },
  }));

  const labelVersion = computed(
    () => msgs.of('version', { version: version.value, buildNumber: buildNumber.value }).value
  );
  const links = computed(() => LINKS.map((item) => ({ ...item, text: msgs.of(item.value).value })));
  const displayTermsOfService = computed(() => user.value?.role === 'supervisor');
  const displayOnGooglePlay = computed(() => !!googlePlayUrl);

  return {
    labelVersion,
    labelReleaseNote: msgs.of('releaseNote'),
    labelTermsOfService: msgs.of('termsOfService'),
    labelTenantOwnTermsOfService: msgs.of('tenantOwnTermsOfService'),
    legalAttribution: msgs.of('legalAttribution'),
    releaseNoteHref: config().app.releaseNoteUrl,
    links,
    displayTermsOfService,
    displayTenantOwnTermsOfService: readonly(existsTenantOwnTermsOfService),
    displayOnGooglePlay,
    toTermsOfService,
    toTenantOwnTermsOfService,
  };
}
