import { ref } from '@vue/composition-api';

import { assertIsDefined } from '@/utils/Asserts';

import { BaseMarkdownClickAnchorPayload } from '../atoms/BaseMarkdownComposable';
import { DialogAnchorConfirm } from './DialogAnchorConfirmComposable';

function useAnchorDialog() {
  const anchorDialog = ref<DialogAnchorConfirm>();
  function clickAnchor(payload: BaseMarkdownClickAnchorPayload) {
    assertIsDefined(anchorDialog.value);
    anchorDialog.value.confirm(payload.event);
  }
  return { anchorDialog, clickAnchor };
}

export function useTermsOfService() {
  return useAnchorDialog();
}
