






import { defineComponent } from '@vue/composition-api';

import BaseMarkdown from '../atoms/BaseMarkdown.vue';
import DialogAnchorConfirm from './DialogAnchorConfirm.vue';
import { useTermsOfService } from './TermsOfServiceComposable';

export default defineComponent({
  name: 'BaseTermsOfService',
  components: { BaseMarkdown, DialogAnchorConfirm },
  props: { body: { type: String, required: true } },
  setup() {
    return useTermsOfService();
  },
});
